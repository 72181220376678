import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Form } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";

import { setUserData, setUserToken } from "../../store/slices/userData";
import { UserInfo } from "../../types/userDataTypes";
import { registration, login, getUserData } from "../../services/apiLogin";
import {
  RequestError,
  RegisterResponse,
  GetUserDataResponse,
} from "../../types/requestTypes";
import { handleFormError } from "../../helpers/errorHandlers";

interface Props {
  show: boolean;
  onHide: () => void;
  handleLogin: (e: boolean) => void;
}

const LoginModal: React.FC<Props> = ({ show, onHide, handleLogin }) => {
  const [isLogin, setIsLogin] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [formData, setFormData] = useState<UserInfo>({} as UserInfo);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleState = (): void => {
    setIsLogin(!isLogin);
  };

  const handleRedirect = (): void => {
    navigate("/password-recovery");
    onHide();
  };

  // Form handlers start
  const handleFormChange = (key: string, data: string): void => {
    setErrorMsg("");
    setFormData({ ...formData, [key]: data });
  };
  const handleError = (error: RequestError): void => {
    const errorString = handleFormError(error);
    setErrorMsg(errorString);
    setIsLoading(false);
  };
  const handleUserData = async (): Promise<void> => {
    const res = await getUserData();
    if (res.status !== "error") {
      const { data } = res as GetUserDataResponse;
      dispatch(setUserData(data as UserInfo));
      handleLogin(true);
      setIsLoading(false);
      onHide();
    } else {
      setErrorMsg("Request error");
    }
  };
  const handleRegistration = async (): Promise<void> => {
    const result = await registration(formData);
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      const regResult = result as RegisterResponse;
      dispatch(setUserToken(regResult.data.access_token as string));
      await handleUserData();
    }
  };
  const handleLoginCall = async (): Promise<void> => {
    const result = await login({
      email: formData.email,
      password: formData.password || "",
    });
    if (result.status === "error") {
      handleError(result as RequestError);
    } else {
      const loginResult = result as RegisterResponse;
      dispatch(setUserToken(loginResult.data.access_token as string));
      await handleUserData();
    }
  };
  const handleFormSubmit = (): void => {
    if (
      !formData.email.toLowerCase().match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setErrorMsg("Enter valid email");
      return;
    }
    setIsLoading(true);
    if (!isLogin) {
      handleRegistration();
    } else {
      handleLoginCall();
    }
  };
  // Form handlers end

  // Check is form button disabled
  const isSubmitDisabled = (): boolean => {
    if (isLogin && (!formData.email || !formData.password)) {
      return true;
    }
    if (
      !isLogin &&
      (!formData.email ||
        !formData.password ||
        !formData.country ||
        !formData.first_name ||
        !formData.last_name ||
        !formData.phone_number ||
        !formData.surname)
    ) {
      return true;
    }
    return false;
  };

  const handleChangeWithValidation = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    const formattedValue = value.match(/^[a-zA-Zа-яЯ-я\s]+/gi)?.join("") || "";

    handleFormChange(name, formattedValue.trimStart());
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {isLogin ? "Вход" : "Регистрация"}
        </Modal.Title>
        <div className="close-btn" onClick={onHide}>
          X
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="login-form">
          <Form.Group className="mb-3 mt-3">
            <Form.Label>Почта:</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              value={formData.email || ""}
              onChange={(e): void => handleFormChange("email", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Пароль:</Form.Label>
            <Form.Control
              type="password"
              placeholder="Пароль"
              value={formData.password || ""}
              onChange={(e): void =>
                handleFormChange("password", e.target.value)
              }
            />
            {isLogin && (
              <p className="mt-2 login-link">
                <span onClick={handleRedirect}>Забыли пароль?</span>
              </p>
            )}
          </Form.Group>
          {!isLogin && (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Имя:</Form.Label>
                <Form.Control
                  maxLength={20}
                  type="text"
                  name="first_name"
                  placeholder="Имя"
                  value={formData.first_name || ""}
                  onChange={handleChangeWithValidation}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Фамилия:</Form.Label>
                <Form.Control
                  maxLength={20}
                  type="text"
                  name="last_name"
                  placeholder="Фамилия"
                  value={formData.last_name || ""}
                  onChange={handleChangeWithValidation}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Отчество:</Form.Label>
                <Form.Control
                  maxLength={20}
                  type="text"
                  name="surname"
                  placeholder="Отчество"
                  value={formData.surname || ""}
                  onChange={handleChangeWithValidation}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Телефон:</Form.Label>

                <PhoneInput
                  international
                  smartCaret={false}
                  defaultCountry="UA"
                  countryCallingCodeEditable={false}
                  placeholder="Телефон"
                  value={formData.phone_number || ""}
                  onChange={(value: string): void => {
                    handleFormChange("phone_number", value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Страна:</Form.Label>
                <Form.Control
                  maxLength={20}
                  type="text"
                  name="country"
                  placeholder="Страна"
                  value={formData.country || ""}
                  onChange={handleChangeWithValidation}
                />
              </Form.Group>
            </>
          )}
        </div>
        {isLogin ? (
          <p className="mb-3 login-link">
            Нет аккаунта?
            <span onClick={toggleState}>Регистрация</span>
          </p>
        ) : (
          <p className="mb-3 login-link">
            Уже есть аккаунт?
            <span onClick={toggleState}>Вход</span>
          </p>
        )}
        {errorMsg && <p className="form-error mb-3">{errorMsg}</p>}
        <button
          className="default-btn"
          type="button"
          onClick={handleFormSubmit}
          disabled={isSubmitDisabled() || isLoading}
        >
          {isLoading ? (
            <Oval
              height={30}
              width={30}
              color="#ffffff"
              visible
              ariaLabel="oval-loading"
              secondaryColor="#ffffff"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            "отправить"
          )}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
